<template>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h1 class="text-center mb-5">
      <img class="img-fluid" src="../assets/logo.png" />
    </h1>
    <h4 class="text-center">
      Redirecionando para um de nossos atendentes...
    </h4>
  </div>
</template>
<script>
export default {
  created() {
    function getRandomNumber() {
      const array = ["554498566641", "554498131825"];
      const index = Math.floor(Math.random() * array.length);
      return array[index];
    }
    const whatsappLink = `https://wa.me/${getRandomNumber()}?text=Oi! Quero aproveitar a oferta e assinar o COF.`;
    window.location.href = whatsappLink;
  }
};
</script>
